import { defineStore } from "pinia";
import service from "../services/chart-group-services";
import { ChartGroup } from "../types/chart-group.interface";
import { ChartGroupState } from "../types/chart-group-state.interface";
import orderBy from "lodash/orderBy";
import { LocalStorageItems } from "../../../types/auth-local-storage/auth-local-storage.enums";
import { PatientConnectClinic } from "../types/patient-connect-clinic.interface";

export const useChartGroupsStore = defineStore("ChartGroupsStore", {
  state: (): ChartGroupState => {
    return {
      chartGroups: null,
      loading: false,
      selectedChartGroupId: null,
      selectedPatientConnectClinicId: null,
    };
  },
  getters: {
    getIsLoading(state: ChartGroupState): boolean {
      return state.loading || !state.chartGroups;
    },
    getChartGroups(state: ChartGroupState): ChartGroup[] {
      return orderBy(state.chartGroups, ["primary", "id"], ["desc", "asc"]);
    },
    getIsChartGroupsFetched(state: ChartGroupState): boolean {
      return Boolean(state.chartGroups);
    },
    getSelectedChartGroup(state: ChartGroupState): ChartGroup | null {
      return (
        state.chartGroups?.find(
          (group) => group.id === state.selectedChartGroupId
        ) || null
      );
    },
    getSelectedChartGroupPatientName(): string {
      return this.getSelectedChartGroup?.name || "";
    },
    getSelectedChartGroupId(state: ChartGroupState): number | null {
      return (
        state.chartGroups?.find(
          (group) => group.id === state.selectedChartGroupId
        )?.id || null
      );
    },
    getIsActingAsCaregiver(): boolean {
      const selectedGroup = this.getSelectedChartGroup;
      return Boolean(!selectedGroup?.primary);
    },
    getSelectedChartGroupName(): string {
      return this.getSelectedChartGroup?.name || "";
    },
    getSelectedPatientConnectClinicId(): number | null {
      return this.selectedPatientConnectClinicId;
    },
    getSelectedPatientConnectClinic(): PatientConnectClinic | null {
      return (
        this.getSelectedChartGroup?.patientConnectClinics.find(
          (chart) => chart.id == this.getSelectedPatientConnectClinicId
        ) || null
      );
    },
    getPatientConnectClinics(): PatientConnectClinic[] {
      return this.getSelectedChartGroup?.patientConnectClinics || [];
    },
    getPrimaryChartGroup(): ChartGroup | null {
      return this.getChartGroups.find((group) => group.primary) || null;
    },
    getAvatarBase64(): string | null {
      return this.getSelectedPatientConnectClinic?.avatar || null;
    },
    getSelectedPatientId(): number | null {
      return this.getSelectedPatientConnectClinic?.patientId || null;
    },
    getSelectedClinicName(): string {
      return this.getSelectedPatientConnectClinic?.clinicName || "";
    },
    getSelectedClinicId(): number | null {
      return this.getSelectedPatientConnectClinic?.clinicId || null;
    },
    getListOfPatientConnectClinicIds(): number[] {
      return (
        this.getSelectedChartGroup?.patientConnectClinics.map(
          (pcc) => pcc.id
        ) || []
      );
    },
  },
  actions: {
    async fetchChartGroups() {
      this.loading = true;
      try {
        this.chartGroups = await service.fetchChartGroups();
        this.setDefaultChartGroup();
        this.setDefaultPatientConnectClinicId();
      } finally {
        this.loading = false;
      }
    },
    setDefaultChartGroup() {
      const urlChartGroupId = this._getUrlChartGroupId();
      if (urlChartGroupId) {
        localStorage.setItem(
          LocalStorageItems.defaultChartGroupId,
          urlChartGroupId.toString() || ""
        );
      }
      const storedChartGroupId = this._getStoredChartGroupId();

      this.selectedChartGroupId = this.hasAccessToChartGroup(
        storedChartGroupId || 0
      )
        ? storedChartGroupId
        : this.getPrimaryChartGroup?.id || this.getChartGroups[0]?.id || null;

      this._clear_chart_group_id_url_param();
    },
    setDefaultPatientConnectClinicId() {
      const localStoragePccId = parseInt(
        localStorage
          .getItem(LocalStorageItems.defaultPatientConnectClinicId)
          ?.toString() || ""
      );
      const pccId = this.hasAccessToPatientConnectClinicId(localStoragePccId)
        ? localStoragePccId
        : this.getSelectedChartGroup?.patientConnectClinics[0].id || null;

      this.selectedPatientConnectClinicId = pccId;
    },
    setChartGroup(groupId: number | null) {
      this.selectedChartGroupId = groupId;
      localStorage.setItem(
        LocalStorageItems.defaultChartGroupId,
        this.selectedChartGroupId?.toString() || ""
      );
      this.setPatientConnectClinicId(
        this.getSelectedChartGroup?.patientConnectClinics[0].id || null
      );
    },
    setPatientConnectClinicId(patientConnectClinicId: number | null) {
      this.selectedPatientConnectClinicId = patientConnectClinicId;
      localStorage.setItem(
        LocalStorageItems.defaultPatientConnectClinicId,
        this.selectedPatientConnectClinicId?.toString() || ""
      );
    },
    hasAccessToChartGroup(groupId: number): boolean {
      return Boolean(this.chartGroups?.some((group) => group.id === groupId));
    },
    hasAccessToPatientConnectClinicId(patientConnectClinicId: number): boolean {
      return Boolean(
        this.getSelectedChartGroup?.patientConnectClinics.some(
          (c) => c.id === patientConnectClinicId
        )
      );
    },
    async uploadAvatar(avatar: FormData) {
      if (this.selectedChartGroupId) {
        this.loading = true;
        try {
          const results = await service.submitPatientAvatar(
            this.selectedChartGroupId,
            avatar
          );
          console.log("results", results);
          const pcc = this.chartGroups
            ?.find((g) => g.id === this.selectedChartGroupId)
            ?.patientConnectClinics.find(
              (pcc) => pcc.id === this.selectedPatientConnectClinicId
            ) || { avatar: null };
          pcc.avatar = pcc?.avatar ? results.data.img : null;
        } finally {
          this.loading = false;
        }
        service.submitPatientAvatar(this.selectedChartGroupId, avatar);
      }
    },
    getClinicNameByPatientConnnectClinicId(
      patientConnectClinicId: number
    ): string {
      return (
        this.getSelectedChartGroup?.patientConnectClinics.find(
          (pcc) => pcc.id === patientConnectClinicId
        )?.clinicName || ""
      );
    },

    _getUrlChartGroupId() {
      const urlParams = new URLSearchParams(window.location.search);
      const id = parseInt(urlParams.get("chart_group_id") || "", 10);
      return !isNaN(id) ? id : null;
    },

    _getStoredChartGroupId() {
      const id = parseInt(
        localStorage.getItem(LocalStorageItems.defaultChartGroupId) || "",
        10
      );
      return !isNaN(id) ? id : null;
    },
    _clear_chart_group_id_url_param() {
      const url = new URL(window.location.href);
      url.searchParams.delete("chart_group_id");
      window.history.replaceState({}, "", url);
    },
  },
});
