
import { defineComponent } from "vue";
import PatientAvatar from "@/components/patient-avatar.vue";
import BaseTypography from "@/ui-library/base-typography/base-typography.vue";
import { useChartGroupsStore } from "../../chart-groups/store/chart-groups-store";

export default defineComponent({
  components: {
    PatientAvatar,
    BaseTypography,
  },
  setup() {
    const store = useChartGroupsStore();
    return {
      store,
    };
  },
});
