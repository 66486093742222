import { AxiosError } from "axios";
import { createErrorToastMessage } from "../../features/toast-messages/types/toast-message";
import { router, Routes } from "../../router";
import { useToastStore } from "@/features/toast-messages/store/toast-message-store";
import { AvaApiErrorData } from "./ava-api-error-data.interface";
import { HttpError } from "./http-error.interface";

export function createHttpError(error: AxiosError<AvaApiErrorData>): HttpError {
  return {
    name: error.name,
    status: error.response?.status,
    message: error.message,
    responseData: error.response?.data,
    errorCode: error.response?.data?.error_code,
    request: error?.request,
  };
}

export function navigateUserAccordingToError(httpError: HttpError) {
  const code = httpError.status;
  const avaInternalErrorCode = httpError.errorCode;
  if (avaInternalErrorCode === "incomplete") {
    useToastStore().toastNewMessage(
      createErrorToastMessage(
        "Error: Incomplete request. Please contact support for assistance.",
        "long"
      )
    );
    router.goTo(Routes.reportIssues);
  } else if (avaInternalErrorCode === "two_factor_incomplete") {
    router.goTo(Routes.twoFactorSignIn);
  } else if (code === 400) {
    useToastStore().toastNewMessage(
      createErrorToastMessage(
        `error code 400: Bad request ${httpError.request}`,
        "long"
      )
    );
  } else if (code === 401) {
    useToastStore().toastAvaApiErrors(httpError);
    router.handleUnauthorizedStatusError();
  } else if (code === 402) {
    useToastStore().toastNewMessage(
      createErrorToastMessage(`error code 402: ${httpError}`, "long")
    );
  } else {
    useToastStore().toastAvaApiErrors(httpError);
  }
}
