import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9207121c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toolbar-logo-container ava-clickable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_img = _resolveComponent("base-img")!
  const _component_toolbar_row_button = _resolveComponent("toolbar-row-button")!
  const _component_media_query_layout = _resolveComponent("media-query-layout")!

  return (_openBlock(), _createBlock(_component_media_query_layout, null, {
    "extra-small": _withCtx(() => [
      _createVNode(_component_toolbar_row_button, null, {
        default: _withCtx(() => [
          _createVNode(_component_base_img, {
            class: "toolbar-logo",
            src: "logo/horizontal-dark-ava-with-logo.png",
            width: "30",
            height: "100%"
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_base_img, {
          class: "toolbar-logo",
          src: "logo/horizontal-dark-ava-with-logo.png",
          width: "70",
          height: "60"
        })
      ])
    ]),
    _: 1
  }))
}