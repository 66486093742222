import http from "@/http/http";
import { AxiosPromise } from "axios";
import { AuthorizeAccountResponseDto } from "../types/account-settings-security/authorize-account-response-dto";
import { AuthorizeEditAccountSettingsPayload } from "../types/account-settings-security/authorize-edit-account-settings-payload";
import { ChangeForgottenPasswordRequestPayload } from "../types/account-settings-security/change-forgotten-password-request-payload";
import { ChangePasswordRequestPayload } from "../types/account-settings-security/change-password-request-payload";
import { EditTwoFactorRequestPayload } from "../types/account-settings-security/edit-two-factor-request-payload";
import { SessionResponseDto } from "../types/auth-session/auth-session.interface";
import { SignInPayload } from "../types/sign-in-payload";
import * as signUpPageServices from "@/pages/authentication/pages/sign-up/services/sign-up-page-services";

function submitTwoFactorCode__retrievesNewJwt(
  token: string
): AxiosPromise<SessionResponseDto> {
  return http.post<SessionResponseDto>(`/two_factor_sessions`, {
    token,
  });
}

// pendingNewJwtRequest prevents multiple requests from being made at the same time
// if multiple requests are made, jti will be incorrect for the second request and
// will fail, causing the user to be logged out
let pendingNewJwtRequest: AxiosPromise<SessionResponseDto> | null = null;
function createSession__retrievesNewJwt(
  payload: SignInPayload
): AxiosPromise<SessionResponseDto> {
  // If there's already a request in progress, return that promise
  if (pendingNewJwtRequest) {
    return pendingNewJwtRequest;
  }

  // Create new request and store its promise
  pendingNewJwtRequest = http.post<SessionResponseDto>("/sessions", payload);

  // Add cleanup handler without modifying the promise chain
  pendingNewJwtRequest.finally(() => {
    pendingNewJwtRequest = null;
  });

  return pendingNewJwtRequest;
}

function renewJwt__retrievesNewJwt(): AxiosPromise<SessionResponseDto> {
  return http.get<SessionResponseDto>("/sessions");
}

function authorizeToEditAccountSettings(
  payload: AuthorizeEditAccountSettingsPayload
): AxiosPromise<AuthorizeAccountResponseDto> {
  return http.post<AuthorizeAccountResponseDto>(
    `/patient_users/${payload.patientUserId}/authorize_password`,
    { old_password: payload.currentPassword }
  );
}

function changePassword(
  payload: ChangePasswordRequestPayload
): AxiosPromise<unknown> {
  return http.put<unknown>(`/patient_users/${payload.patientUserId}`, {
    patient_user: {
      current_password: payload.currentPassword,
      password: payload.newPassword,
      password_confirmation: payload.confirmPassword,
    },
  });
}

function changeTwoFactor(
  payload: EditTwoFactorRequestPayload
): AxiosPromise<unknown> {
  return http.put<unknown>(`/two_factor`, {
    patient_user: {
      two_factor_enabled: payload.isEnabled,
    },
    two_factor_code: payload.twoFactorCode,
  });
}

function emailTwoFactorCode(): AxiosPromise<unknown> {
  return http.get<unknown>(`/two_factor_sessions`, {
    params: { send_via: "email" },
  });
}

function emailPasswordResetToken(email: string): AxiosPromise<unknown> {
  return http.get<unknown>(`/password_reset`, {
    params: { email },
  });
}

function changeForgottenPassword(
  payload: ChangeForgottenPasswordRequestPayload
): AxiosPromise<unknown> {
  return http.put<unknown>(`/password_reset`, {
    email: payload.email,
    password_reset_token: payload.token,
    patient_user: {
      password: payload.newPassword,
      password_confirmation: payload.confirmPassword,
    },
  });
}

export default {
  ...signUpPageServices,
  authorizeToEditAccountSettings,
  changePassword,
  changeTwoFactor,
  emailTwoFactorCode,
  emailPasswordResetToken,
  changeForgottenPassword,
  createSession__retrievesNewJwt,
  submitTwoFactorCode__retrievesNewJwt,
  renewJwt__retrievesNewJwt,
};
