/**
 * This is a pinia version of message store. We need to retire the vuex version of this store.
 */

import { defineStore } from "pinia";
import { HttpError } from "@/http/types/http-error.interface";
import { convertAvaApiErrorDataToMessageList } from "@/http/types/ava-api-error-data.functions";
import uniqBy from "lodash/uniqBy";
import {
  ToastMessage,
  createToastMessage,
  createErrorToastMessage,
  findToastMessageIndexByMessage,
} from "../types/toast-message";
import { ToastMessagesState } from "../types/toast-messages-state.interface";

const GENERIC_MESSAGE =
  "Oh... Something went wrong. Please refresh and try again";
const SUCCESSFUL_MESSAGE = "Successfully submitted!";

export const useToastStore = defineStore("toastMessagesStore", {
  state: (): ToastMessagesState => ({
    toastMessages: [],
  }),

  getters: {
    getToastMessages: (state): ToastMessage[] => {
      return uniqBy(state.toastMessages, "message");
    },
  },

  actions: {
    addMessage(message: ToastMessage) {
      const { isFound } = findToastMessageIndexByMessage(
        this.toastMessages,
        message.message
      );
      if (!isFound) {
        this.toastMessages.push(message);
        // Only set timeout if timeToView is provided (not null)
        if (message.timeToView !== null) {
          setTimeout(() => {
            this.removeSingleMessage(message.message);
          }, message.timeToView);
        }
      }
    },

    removeMessages() {
      this.toastMessages = [];
    },

    removeSingleMessage(message: string) {
      const { messageIndex, isFound } = findToastMessageIndexByMessage(
        this.toastMessages,
        message
      );
      if (isFound) {
        this.toastMessages.splice(messageIndex, 1);
      }
    },

    toastSimpleMessage(message: string) {
      this.addMessage(createToastMessage(message));
    },

    toastNewMessage(payload: ToastMessage) {
      this.addMessage(payload);
    },

    toastGeneralError() {
      this.addMessage(createErrorToastMessage(GENERIC_MESSAGE));
    },

    toastSuccessfulSubmission(timeToView?: number) {
      this.toastNewMessage(
        createToastMessage(SUCCESSFUL_MESSAGE, "notification", timeToView)
      );
    },

    toastAvaApiErrors(error: HttpError) {
      const responseDataErrors = error.responseData?.errors;
      const errorsCodeMessage = error.responseData?.error_code || "";

      if (responseDataErrors) {
        convertAvaApiErrorDataToMessageList(responseDataErrors).forEach(
          (msg: string) => this.toastNewMessage(createErrorToastMessage(msg))
        );
      } else if (errorsCodeMessage) {
        this.toastNewMessage(createErrorToastMessage(errorsCodeMessage));
      } else {
        this.toastNewMessage(createErrorToastMessage("An error occurred"));
      }
    },
  },
});
