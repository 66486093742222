import http from "@/http/http";
import { AxiosPromise } from "axios";
import {
  TestResultLineGraphDetailsItem,
  TestResultLineGraphDetailsItemRequestPayload,
} from "../../types/test-result-graph-details-item";
import { TestResultItemDto } from "../../types/test-result-item";
import {
  TestResultItemDetailRequestPayload,
  TestResultItemDetailResponseDto,
} from "../../types/test-result-item-detail";

function fetchTestResults(
  chartGroupId: number
): AxiosPromise<{ data: TestResultItemDto[] }> {
  return http.get<{ data: TestResultItemDto[] }>(
    `/chart_groups/${chartGroupId}/results`
  );
}

function fetchTestResultDetails(
  payload: TestResultItemDetailRequestPayload
): AxiosPromise<TestResultItemDetailResponseDto> {
  return http.get<TestResultItemDetailResponseDto>(
    `/chart_groups/${payload.chartGroupId}/results/${payload.resultId}`
  );
}

function fetchTestResultLineGraph(
  payload: TestResultLineGraphDetailsItemRequestPayload
): AxiosPromise<TestResultLineGraphDetailsItem> {
  return http.get<TestResultLineGraphDetailsItem>(
    `/chart_groups/${payload.chartGroupId}/result_graphs/${payload.testResultDetailId}`
  );
}

export default {
  fetchTestResults,
  fetchTestResultDetails,
  fetchTestResultLineGraph,
};
