/* eslint-disable @typescript-eslint/no-explicit-any */

import { InternalAxiosRequestConfig } from "axios";

// Types
export interface DuplicateRequestError {
  __CANCEL__: boolean;
  promise: Promise<any>;
}

// Type guard
export function isDuplicateRequestError(
  error: any
): error is DuplicateRequestError {
  return error && "__CANCEL__" in error && "promise" in error;
}

// Request cache store
const requestCache = new Map<string, Promise<any>>();

// Cache key generator
export const generateCacheKey = (
  config: InternalAxiosRequestConfig
): string => {
  const { method, url, params } = config;

  // Handle data stringification consistently
  let dataString = "";
  if (config.data) {
    try {
      // If data is already a string, parse it to object first
      const dataObj =
        typeof config.data === "string" ? JSON.parse(config.data) : config.data;
      dataString = JSON.stringify(dataObj);
    } catch {
      // If parsing fails, use the data as is
      dataString = String(config.data);
    }
  }

  return `${method}-${url}-${JSON.stringify(params)}-${dataString}`;
};

// Deduplication helpers
export const deduplication = {
  handleRequest: async (
    config: InternalAxiosRequestConfig
  ): Promise<InternalAxiosRequestConfig> => {
    const cacheKey = generateCacheKey(config);

    if (config.method?.toLowerCase() !== "get") {
      const existingRequest = requestCache.get(cacheKey);
      if (existingRequest) {
        return Promise.reject({
          __CANCEL__: true,
          promise: existingRequest,
        });
      }

      const promise = new Promise((resolve, reject) => {
        config.__resolve = resolve;
        config.__reject = reject;
      });
      requestCache.set(cacheKey, promise);
    }

    return config;
  },

  handleResponse: (response: any) => {
    const config = response.config as InternalAxiosRequestConfig;
    const cacheKey = generateCacheKey(config);

    if (config.__resolve) {
      config.__resolve(response);
    }

    requestCache.delete(cacheKey);
    return response;
  },

  handleError: (error: any) => {
    if (isDuplicateRequestError(error)) {
      return error.promise;
    }

    const config = error.config as InternalAxiosRequestConfig;
    if (config) {
      const cacheKey = generateCacheKey(config);

      try {
        if (config.__reject) {
          config.__reject(error);
        }
      } finally {
        requestCache.delete(cacheKey);
      }
    }

    return Promise.reject(error);
  },
  // Add a helper to get cache size for debugging
  getCacheSize: () => {
    return requestCache.size;
  },

  // Add a debug helper to see what's in cache
  getCache: () => {
    return Array.from(requestCache.keys());
  },

  clearCache: () => {
    requestCache.clear();
  },
};

// Type declaration
declare module "axios" {
  interface InternalAxiosRequestConfig {
    __resolve?: (value: any) => void;
    __reject?: (error: any) => void;
  }
}
