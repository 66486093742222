import appointmentsApi from "../services/appointments-service";
import { ActionTypes } from "@/store/constants/action-types";
import { AvaStore } from "@/store/types/store.type";
import { MutationTypes } from "@/store/constants/mutation-types";
import { createAppointmentFromDto } from "@/features/shared-appointments/types/appointment";
import { CancelAppointmentPayload } from "../types/cancel-appointment-payload";
import { HttpError } from "@/http/types/http-error.interface";

export interface AppointmentsActions {
  [ActionTypes.FETCH_APPOINTMENTS]: (
    store: AvaStore,
    patientId: number
  ) => Promise<void>;
  [ActionTypes.CANCEL_APPOINTMENT]: (
    store: AvaStore,
    payload: CancelAppointmentPayload
  ) => Promise<void>;
}

export const appointmentsActions: AppointmentsActions = {
  async [ActionTypes.FETCH_APPOINTMENTS]({ commit }, patientId) {
    try {
      commit(MutationTypes.APPOINTMENTS__TOGGLE_LOADING, true);
      const results = await appointmentsApi.getAppointments(patientId);
      commit(
        MutationTypes.APPOINTMENTS__SET_BOOKED_APPOINTMENTS,
        results.data.appointments.map(createAppointmentFromDto)
      );
    } catch (error) {
      commit(MutationTypes.APPOINTMENTS__SET_ERROR, error as HttpError);
      throw error;
    } finally {
      commit(MutationTypes.APPOINTMENTS__TOGGLE_LOADING, false);
    }
  },
  async [ActionTypes.CANCEL_APPOINTMENT]({ commit, dispatch }, payload) {
    try {
      commit(MutationTypes.APPOINTMENTS__TOGGLE_LOADING, true);
      await appointmentsApi.cancelAppointments(
        payload.chartGroupId,
        payload.appointmentId
      );
      commit(
        MutationTypes.APPOINTMENTS__CANCEL_APPOINTMENT,
        payload.appointmentId
      );
      dispatch(
        ActionTypes.TOAST_SIMPLE_MESSAGE,
        "Appointment was successfully cancelled"
      );
    } catch (error) {
      commit(MutationTypes.APPOINTMENTS__SET_ERROR, error as HttpError);
      throw error;
    } finally {
      commit(MutationTypes.APPOINTMENTS__TOGGLE_LOADING, false);
    }
  },
};
