import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b3bd2332"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "profile-card" }
const _hoisted_2 = { class: "col-xs-3" }
const _hoisted_3 = { class: "col-xs-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_patient_avatar = _resolveComponent("patient-avatar")!
  const _component_base_typography = _resolveComponent("base-typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_patient_avatar, {
        class: "avatar",
        avatarImg: _ctx.store.getAvatarBase64
      }, null, 8, ["avatarImg"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_base_typography, {
        subtitle2: "",
        noMargin: "",
        "text-align": "left"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.store.getSelectedChartGroupPatientName), 1)
        ]),
        _: 1
      })
    ])
  ]))
}