
import { computed, defineComponent, PropType } from "vue";
import BaseTypography from "@/ui-library/base-typography/base-typography.vue";
import BaseListItem from "@/ui-library/base-list-item/base-list-item.vue";
import BaseDropDownList from "@/ui-library/base-drop-down-list/base-drop-down-list.vue";
import PatientProfileDependantButton from "./patient-profile-dependant-button.vue";
import ToolbarRowButton from "./toolbar-row-button.vue";
import ChevronIcon from "@/ui-library/icons/chevron-icon.vue";
import { ChartGroup } from "../../chart-groups/types/chart-group.interface";

export default defineComponent({
  emits: {
    goToManageDependants: null,
    chooseChartGroup: (id: number) => Boolean(id),
  },
  props: {
    isCurrentlyActingCaregiver: {
      type: Boolean,
      required: true,
    },
    currentChartGroupName: {
      type: String,
      required: true,
    },
    chartGroups: {
      type: Array as PropType<ChartGroup[]>,
      default: () => [],
    },
  },
  setup(props) {
    const caregiverNamePhrase = computed(
      () => `You are acting on behalf of ${props.currentChartGroupName}`
    );
    return {
      myProfileTitle: "My profile",
      manageDependantsTitle: "Manage dependants",
      namePhrase: computed(() =>
        props.isCurrentlyActingCaregiver && props.currentChartGroupName
          ? caregiverNamePhrase.value
          : props.currentChartGroupName
      ),
    };
  },
  components: {
    BaseTypography,
    BaseListItem,
    BaseDropDownList,
    PatientProfileDependantButton,
    ToolbarRowButton,
    ChevronIcon,
  },
});
