import http from "@/http/http";
import { ChartGroupDto } from "../types/chart-group-dto.interface";
import { createChartGroup } from "../types/chart-group-functions";
import { ChartGroup } from "../types/chart-group.interface";
import { AxiosPromise } from "axios";

function fetchChartGroups(): Promise<ChartGroup[]> {
  return http.get(`/chart_groups`).then((res) => {
    return res.data.chart_groups.map((c: ChartGroupDto) => {
      return createChartGroup(c);
    });
  });
}

function submitPatientAvatar(
  chartGroupId: number,
  imgData: FormData
): AxiosPromise<{ img: string }> {
  return http.post<{ img: string }>(
    `/chart_groups/${chartGroupId}/patient_avatar`,
    imgData,
    { headers: { "Content-Type": "multipart/form-data" } }
  );
}

// function updatePatientConnectClinic(
//   patientId: number,
//   patientConnectClinicId: number,
//   isAccepted: boolean
// ) {
//   return http.put(
//     `/patients/${patientId}/patient_connect_clinics/${patientConnectClinicId}`,
//     {
//       patient_connect_clinic: {
//         [isAccepted ? "accept" : "decline"]: 1,
//       },
//     }
//   );
// }
// function resetPatientConnectClinic(
//   patientId: number,
//   patientConnectClinicId: number
// ) {
//   return http.put(
//     `/patients/${patientId}/patient_connect_clinics/${patientConnectClinicId}`,
//     {
//       patient_connect_clinic: {
//         undo: 1,
//       },
//     }
//   );
// }

// function authorizedClinic(
//   patientId: number,
//   clinicId: number
// ): AxiosPromise<{ authorized_clinic: AuthorizedClinicDto }> {
//   return http.post<{ authorized_clinic: AuthorizedClinicDto }>(
//     `/patients/${patientId}/authorized_clinics`,
//     { clinic_id: clinicId }
//   );
// }

// function deAuthorizedClinic(
//   patientId: number,
//   clinicId: number
// ): AxiosPromise<unknown> {
//   return http.delete(`/patients/${patientId}/authorized_clinics/${clinicId}`);
// }

// function getSearchedClinics(
//   searchTerm: string
// ): AxiosPromise<{ clinics: SearchedClinicDto[] }> {
//   const params = {
//     search: searchTerm,
//   };
//   return http.get<{ clinics: SearchedClinicDto[] }>(`/clinics`, { params });
// }

export default {
  fetchChartGroups,
  submitPatientAvatar,
};
