import { defineStore } from "pinia";
import { PatientProfile } from "../features/patient-profile/types/patient-profile";

export const usePatientProfileStore = defineStore("PatientProfileStore", {
  state: (): PatientProfile => {
    return {
      id: null,
      firstName: null,
      middleMame: null,
      lastName: null,
      address1: null,
      address2: null,
      city: null,
      province: null,
      country: null,
      postalCode: null,
      email: null,
      phonePrimary: null,
      phoneSecondary: null,
      textPhonePrimary: null,
      textPhoneSecondary: null,
      sex: null,
      dateOfBirth: null,
      emergencyPhone: null,
      emergencyName: null,
      emergencyRelationship: null,
      primaryPhysicianConfirmed: null,
      primaryClinicConfirmed: null,
      appointmentReminders: null,
      smsAppointmentReminders: null,
      primaryPhysicianName: null,
      primaryClinicName: null,
      base64Avatar: null,
      phones: [],
    };
  },
  getters: {
    getPatientId: (state) => state.id,
  },
  actions: {
    setPatientId(patientId: number | null) {
      this.id = patientId;
    },
    async fetchPatientProfile() {
      console.log("fetchPatientProfile can be deleted");
      // const profile = await avaVuexStore.dispatch(
      //   ActionTypes.FETCH_PATIENT_PROFILE,
      //   patientId
      // );
      // if (profile) {
      //   this.$state = profile;
      //   this.setPatientIdLastRetrievedOnLocalStorage(patientId);
      // }
    },
    setPatientIdLastRetrievedOnLocalStorage() {
      console.log("setPatientIdLastRetrievedOnLocalStorage can be deleted");
      // localStorage.setItem(
      //   LocalStorageItems.patientIdLastRetrieved,
      //   patientId?.toString() || ""
      // );
    },
  },
});
