import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_chevron_icon = _resolveComponent("chevron-icon")!
  const _component_patient_profile_dependant_button = _resolveComponent("patient-profile-dependant-button")!
  const _component_toolbar_row_button = _resolveComponent("toolbar-row-button")!
  const _component_base_typography = _resolveComponent("base-typography")!
  const _component_base_list_item = _resolveComponent("base-list-item")!
  const _component_base_drop_down_list = _resolveComponent("base-drop-down-list")!

  return (_openBlock(), _createBlock(_component_base_drop_down_list, { align: "right" }, {
    button: _withCtx(({ isOpen }) => [
      _createVNode(_component_toolbar_row_button, null, {
        default: _withCtx(() => [
          (_ctx.chartGroups.length)
            ? (_openBlock(), _createBlock(_component_chevron_icon, {
                key: 0,
                direction: isOpen ? 'up' : 'down'
              }, null, 8, ["direction"]))
            : _createCommentVNode("", true),
          _createVNode(_component_patient_profile_dependant_button, {
            buttonTitle: _ctx.namePhrase,
            isCurrentlyActingCaregiver: _ctx.isCurrentlyActingCaregiver
          }, null, 8, ["buttonTitle", "isCurrentlyActingCaregiver"])
        ]),
        _: 2
      }, 1024)
    ]),
    list: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chartGroups, (chartGroup) => {
        return (_openBlock(), _createBlock(_component_base_list_item, {
          key: chartGroup.id,
          class: "list-item",
          onClick: ($event: any) => (_ctx.$emit('chooseChartGroup', chartGroup.id))
        }, {
          default: _withCtx(() => [
            _createVNode(_component_base_typography, { body2: "" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(chartGroup.name), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1032, ["onClick"]))
      }), 128))
    ]),
    _: 1
  }))
}