import { HttpError } from "@/http/types/http-error.interface";
import { MutationTypes } from "@/store/constants/mutation-types";
import { BiometricGraphDetails } from "../types/biometric-graph/biometric-graph-details";
import { BiometricGraphState } from "../types/biometric-graph/biometric-graph-state";

export const biometricGraphMutations = {
  [MutationTypes.BIOMETRIC_GRAPH__TOGGLE_LOADING](
    state: BiometricGraphState,
    isLoading: boolean
  ) {
    state.loading = isLoading;
  },
  [MutationTypes.BIOMETRIC_GRAPH__SET_ERROR](
    state: BiometricGraphState,
    error: HttpError
  ) {
    console.log("Error: ", error);
  },
  [MutationTypes.BIOMETRIC_GRAPH__SET_GRAPH_DETAILS](
    state: BiometricGraphState,
    payload: BiometricGraphDetails
  ) {
    state.graphDetails = payload;
  },
};

export type BiometricGraphMutations = typeof biometricGraphMutations;
