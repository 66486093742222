import { BiometricGraphDetails } from "./biometric-graph-details";

export const createDefaultBiometricGraphState = () => ({
  loading: false,
  error: null,
  graphDetails: null,
});

export interface BiometricGraphState {
  loading: boolean;
  graphDetails: BiometricGraphDetails | null;
}
