
import { computed, defineComponent } from "vue";
import { useStore } from "@/store";
import { MutationTypes } from "@/store/constants/mutation-types";
import BaseTransitionGroupFlyDownUp from "@/ui-library/base-transition-group/base-transition-group-fly-down-up.vue";
import ToastMessage from "./components/toast-message.vue";
import { ToastMessage as ToastMessageInterface } from "./types/toast-message";
import { useToastStore } from "./store/toast-message-store";

export default defineComponent({
  setup() {
    const vuexStore = useStore();
    const toastStore = useToastStore();

    const removeMessage = (msg: string) => {
      vuexStore.commit(MutationTypes.TOAST__REMOVE_SINGLE_MESSAGE, msg);
      toastStore.removeSingleMessage(msg);
    };

    return {
      toastMessages: computed((): ToastMessageInterface[] => [
        ...vuexStore.getters.getToastMessages,
        ...toastStore.getToastMessages,
      ]),
      removeMessage,
    };
  },
  components: {
    BaseTransitionGroupFlyDownUp,
    ToastMessage,
  },
});
