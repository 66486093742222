import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toolbar_side_menu = _resolveComponent("toolbar-side-menu")!
  const _component_chart_group_drop_down = _resolveComponent("chart-group-drop-down")!
  const _component_logout_button_content = _resolveComponent("logout-button-content")!
  const _component_toolbar_row_button = _resolveComponent("toolbar-row-button")!
  const _component_toolbar_layout = _resolveComponent("toolbar-layout")!

  return (_openBlock(), _createBlock(_component_toolbar_layout, { onLogoClicked: _ctx.goToDashboard }, {
    "left-side": _withCtx(() => [
      _createVNode(_component_toolbar_side_menu, { onLogout: _ctx.logout }, null, 8, ["onLogout"])
    ]),
    "right-side": _withCtx(() => [
      _createVNode(_component_chart_group_drop_down, {
        chartGroups: _ctx.chartGroupStore.getChartGroups,
        isCurrentlyActingCaregiver: _ctx.chartGroupStore.getIsActingAsCaregiver,
        currentChartGroupName: _ctx.chartGroupStore.getSelectedChartGroupName,
        onChooseChartGroup: _ctx.chartGroupStore.setChartGroup
      }, null, 8, ["chartGroups", "isCurrentlyActingCaregiver", "currentChartGroupName", "onChooseChartGroup"]),
      _createVNode(_component_toolbar_row_button, { onClick: _ctx.logout }, {
        default: _withCtx(() => [
          _createVNode(_component_logout_button_content)
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["onLogoClicked"]))
}