import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import {
  isResponseSuccessful,
  createPatientApiBaseUrl,
} from "./types/http.functions";
import { decodeUrl, setConfigHeaderWithAuthToken } from "./interceptors";
import {
  createHttpError,
  navigateUserAccordingToError,
} from "./types/http-error.functions";
import { Http } from "./types/http.interface";
import { storeAuthTokenOnLocalDevice } from "../features/the-authentication/services/types/auth-services.functions";
import { AvaApiErrorData } from "./types/ava-api-error-data.interface";
import {
  deduplication,
  isDuplicateRequestError,
} from "./types/http-deduplication";

const http = axios.create({
  baseURL: createPatientApiBaseUrl(),
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// 1. Auth Token Interceptor
http.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    return setConfigHeaderWithAuthToken(config);
  },
  (error: AxiosError) => Promise.reject(error)
);

// 2. URL Decoder Interceptor
http.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    return decodeUrl(config);
  },
  (error: AxiosError) => Promise.reject(error)
);

// 3. Deduplication Request Interceptor
http.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    try {
      return await deduplication.handleRequest(config);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error: AxiosError) => Promise.reject(error)
);

// 4. Store Auth Token Interceptor
http.interceptors.response.use(
  (response) => {
    storeAuthTokenOnLocalDevice(response);
    return response;
  },
  (error: AxiosError) => Promise.reject(error)
);

// 5. Deduplication Response Interceptor
http.interceptors.response.use(
  (response) => {
    return deduplication.handleResponse(response);
  },
  (error: AxiosError<AvaApiErrorData>) => {
    const result = deduplication.handleError(error);
    if (isDuplicateRequestError(error)) {
      return result;
    }
    return Promise.reject(error);
  }
);

// 6. Error Handler Interceptor
http.interceptors.response.use(
  (response) => response,
  (error: AxiosError<AvaApiErrorData>) => {
    const httpError = createHttpError(error);
    navigateUserAccordingToError(httpError);
    return Promise.reject(httpError);
  }
);

// Debug logging in development
// if (process.env.NODE_ENV === "development") {
//   setInterval(() => {
//     console.debug("Request cache size:", deduplication.getCacheSize());
//     console.debug("Cached requests:", deduplication.getCache());
//   }, 10000);
// }

export default {
  ...http,
  isResponseSuccessful,
  createPatientApiBaseUrl,
} as Http;
