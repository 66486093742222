import { defineStore } from "pinia";
import { SessionState } from "./types/session/session-state.interface";
import { SignInPayload } from "../features/the-authentication/types/sign-in-payload";
import authApi from "../features/the-authentication/services/auth-services";
import { clearLocalStorageAuthItems } from "../types/auth-local-storage/auth-local-storage.functions";
import {
  createAuthSessionFromDto,
  isRegistrationCompleteBySession,
  isTwoFactorEnabledInSession,
} from "../features/the-authentication/types/auth-session/auth-session.functions";
import { LocalStorageItems } from "../types/auth-local-storage/auth-local-storage.enums";
import {
  AuthSession,
  SessionResponseDto,
} from "../features/the-authentication/types/auth-session/auth-session.interface";
import { AxiosResponse } from "axios";
import { Routes, router } from "../router";
import { store as avaVuexStore } from "@/store";
import { MutationTypes } from "./constants/mutation-types";

export const useSessionStore = defineStore("SessionStore", {
  state: (): SessionState => {
    return {
      loading: false,
      landingPage: "/dashboard",
      patientUserId: null, // patientUserId that belongs to the person signing in. Never a "dependant" patientUserId
      patientId: null, // patientId that belongs to the person signing in. Never a "dependant" patientId
    };
  },
  getters: {
    getSessionOwnerPatientUserId(state): number | null {
      return state.patientUserId;
    },
    getSessionOwnerPatientId(): number | null {
      return null;
      // throw new Error(
      //   "Get rid of getSessionOwnerPatientId and use chart group store"
      // );
      // return state.patientId;
    },
    getIsSessionFetched(state): boolean {
      return Boolean(state.patientUserId);
    },
    getIsLoading(state): boolean {
      return state.loading;
    },
  },
  actions: {
    async createSession(payload: SignInPayload) {
      try {
        this.loading = true;
        clearLocalStorageAuthItems();

        const response = await authApi.createSession__retrievesNewJwt(payload);
        // put jwt on local storage
        this.saveJwtOnLocalStorage(response);

        // set up state
        const session = createAuthSessionFromDto(response);
        this.saveSession(session);

        // navigate to next page
        this.navigateAfterLogin(session);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    saveSession(session: AuthSession) {
      // VUEX was originally used for state management
      // need to commit to vuex store until we fully deprecate avaVuexStore
      avaVuexStore.commit(MutationTypes.AUTH__SET_SESSION, session);
      this.patientUserId = session.patientUserId || null;
      this.patientId = session.patientId || null;
      localStorage.setItem(
        LocalStorageItems.patientUserId,
        session.patientUserId?.toString() || ""
      );
    },
    async renewJwt() {
      const response = await authApi.renewJwt__retrievesNewJwt();
      const session = createAuthSessionFromDto(response);
      this.saveJwtOnLocalStorage(response);
      this.saveSession(session);
    },
    saveJwtOnLocalStorage(response: AxiosResponse<SessionResponseDto>) {
      const jwt = this.getJwtFromHttpResponse(response);
      localStorage.setItem(LocalStorageItems.jwt, jwt);
    },
    getJwtFromHttpResponse(response: AxiosResponse<SessionResponseDto>) {
      return response.headers.authorization;
    },
    navigateAfterLogin(session: AuthSession) {
      if (isTwoFactorEnabledInSession(session)) {
        return router.goTo(Routes.twoFactorSignIn);
      } else if (!isRegistrationCompleteBySession(session)) {
        router.goTo(Routes.completeRegistration);
      } else {
        router.push(this.landingPage);
      }
    },
    setLandingPage(path: string) {
      this.landingPage = path;
    },
  },
});
