import http from "@/http/http";
import { AxiosPromise } from "axios";
import {
  BiometricGraphRequestPayload,
  createParametersForBiometricGraphRequest,
} from "../types/biometric-graph/biometric-graph-request-payload";
import {
  BiometricsRequestPayload,
  createBiometricsRequestDto,
} from "../../../features/biometric-form/types/biometrics-request-payload";
import { BiometricGraphDetails } from "../types/biometric-graph/biometric-graph-details";

function fetchBiometric(
  payload: BiometricGraphRequestPayload
): AxiosPromise<BiometricGraphDetails> {
  const params = createParametersForBiometricGraphRequest(payload);
  return http.get(`/chart_groups/${payload.chartGroupId}/vital_graphs`, {
    params,
  });
}

function addBiometric(
  chartGroupId: number,
  patientId: number,
  biometricRequestPayload: BiometricsRequestPayload,
  graphRequestPayload?: BiometricGraphRequestPayload
) {
  const graphParams = graphRequestPayload
    ? createParametersForBiometricGraphRequest(graphRequestPayload)
    : {};
  return http.post(`/chart_groups/${chartGroupId}/vitals`, {
    ...createBiometricsRequestDto(patientId, biometricRequestPayload),
    ...graphParams,
  });
}

export default {
  fetchBiometric,
  addBiometric,
};
