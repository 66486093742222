import http from "@/http/http";
import { AxiosPromise } from "axios";
import {
  AppointmentSlotsResponseDto,
  GetBookableAppointmentSlotsPayload,
} from "../types/appointment-slots";
import {
  BookAppointmentPayload,
  BookAppointmentPostResponseDto,
} from "../types/book-appointment";
import { AppointmentResponseDto } from "@/features/shared-appointments/types/appointment";
import { BookingAppointmentEmployeeRequestDto } from "../types/booking-appointment-employee/index";
import { BookingAppointmentTypeRequestDto } from "../types/booking-appointment-type/index";
import { PatientConnectClinicBookingPermissionDto } from "../types/patient-connect-clinic-booking-permission/patient-connect-clinic-booking-permission-dto.interface";

function getPatientConnectClinicsBookingSettings(
  chartGroupId: number
): AxiosPromise<{
  patient_connect_clinics: PatientConnectClinicBookingPermissionDto[];
}> {
  return http.get<{
    patient_connect_clinics: PatientConnectClinicBookingPermissionDto[];
  }>(`/chart_groups/${chartGroupId}/clinics`);
}

function getBookingAppointmentPositions(
  chartGroupId: number,
  clinicId: number
): AxiosPromise<{ positions: BookingAppointmentEmployeeRequestDto[] }> {
  return http.get<{ positions: BookingAppointmentEmployeeRequestDto[] }>(
    `/chart_groups/${chartGroupId}/clinics/${clinicId}/positions`
  );
}

function getBookingAppointmentTypes(
  chartGroupId: number,
  practitionerId: number
): AxiosPromise<{ appointment_types: BookingAppointmentTypeRequestDto[] }> {
  return http.get<{ appointment_types: BookingAppointmentTypeRequestDto[] }>(
    `/chart_groups/${chartGroupId}/positions/${practitionerId}/appointment_types`
  );
}

function getBookableAppointmentSlots(
  payload: GetBookableAppointmentSlotsPayload
): AxiosPromise<AppointmentSlotsResponseDto> {
  const params = {
    position_id: payload.positionId,
    appointment_type_id: payload.appointmentTypeId,
    start_date: payload.startDate,
    end_date: payload.endDate,
  };
  return http.get<AppointmentSlotsResponseDto>(
    `/chart_groups/${payload.chartGroupId}/available_appointments`,
    { params }
  );
}

function bookAppointment(
  payload: BookAppointmentPayload
): AxiosPromise<BookAppointmentPostResponseDto> {
  const date = payload.startDate.split("T")[0];
  const data = {
    appointment: {
      patient_id: payload.patientId,
      start_date: payload.startDate,
      end_date: payload.endDate,
      date,
      position_id: payload.positionId,
      status: "Booked",
      appointment_type_id: payload.appointmentTypeId,
      notes: payload.notes,
    },
  };
  return http.post<BookAppointmentPostResponseDto>(
    `/chart_groups/${payload.chartGroupId}/appointments`,
    data
  );
}

function getAppointments(
  chartGroupId: number
): AxiosPromise<{ appointments: AppointmentResponseDto[] }> {
  return http.get<{ appointments: AppointmentResponseDto[] }>(
    `/chart_groups/${chartGroupId}/appointments`
  );
}

function cancelAppointments(
  chartGroupId: number,
  appointmentId: number
): AxiosPromise {
  return http.delete(
    `/chart_groups/${chartGroupId}/appointments/${appointmentId}`
  );
}

export default {
  bookAppointment,
  getPatientConnectClinicsBookingSettings,
  getBookingAppointmentPositions,
  getBookingAppointmentTypes,
  getBookableAppointmentSlots,
  getAppointments,
  cancelAppointments,
};
