import { defineStore } from "pinia";
import service from "../services/patient-connect-clinic-services";
import { PatientConnectClinicDto } from "../types/patient-connect-clinic-dto.interface";
import { PatientConnectClinicState } from "../types/patient-connect-clinic-state.interface";
import { PatientConnectClinic } from "../types/patient-connect-clinic.interface";
import { usePatientProfileStore } from "../../../store/patient-profile-store";

export const usePatientConnectClinicStore = defineStore(
  "PatientConnectClinicStore",
  {
    state: (): PatientConnectClinicState => {
      return {
        patientConnectClinics: [],
        openInvitationModal: false,
        loading: false,
      };
    },
    getters: {
      getInvites: (state) => state.patientConnectClinics,
      getIsLoading: (state) => state.loading,
      getInvitationIsPending: (state) =>
        state.patientConnectClinics.some((c) => c.status === "Pending"),
      getPendingInvites: (state) =>
        state.patientConnectClinics.filter((c) => c.status === "Pending"),
      getAcceptedInvites: (state) =>
        state.patientConnectClinics.filter((c) => c.status === "Active"),
      getIsInvitationModalOpen: (state) => state.openInvitationModal,
    },
    actions: {
      async fetchPatientConnectClinics(patientId: number | null) {
        if (patientId) {
          this.loading = true;
          try {
            const res = await service.fetchPatientConnectClinics(patientId);
            this.patientConnectClinics = res.data.patient_connect_clinics.map(
              (c: PatientConnectClinicDto): PatientConnectClinic => ({
                id: c.id,
                status: c.status,
                clinicId: c.clinic.id,
                clinicName: c.clinic.name,
                clinicAddress: c.clinic_address,
                active: c.active,
                notes: c.notes,
              })
            );
            this.openInvitationModal =
              this.getInvitationIsPending || this.getInvites.length === 0;
          } finally {
            this.loading = false;
          }
        }
      },
      async respondToInvitation(
        patientConnectClinicId: number,
        inviteResponse: boolean
      ) {
        const patientProfileStore = usePatientProfileStore();
        if (patientProfileStore.getPatientId) {
          await service.updatePatientConnectClinic(
            patientProfileStore.getPatientId,
            patientConnectClinicId,
            inviteResponse
          );
        }
      },
      async resetInvitation(patientConnectClinicId: number) {
        const patientProfileStore = usePatientProfileStore();
        if (patientProfileStore.getPatientId) {
          await service.resetPatientConnectClinic(
            patientProfileStore.getPatientId,
            patientConnectClinicId
          );
        }
      },
      closeInvitationModal() {
        this.openInvitationModal = false;
      },
    },
  }
);
