
import { defineComponent, toRefs } from "vue";
import { useHandleLandingPageNavigation } from "./composition/use-handle-landing-page-navigation";
import { useFetchPatientConnectClinics } from "./composition/use-fetch-patient-connect-clinics";
import { watch } from "vue";
import { useCheckJwtSessionOnVisibilityChange } from "../jwt-session-management/composition/use-check-jwt-session-on-visibility-change";
import { useFetchChartGroups } from "./composition/use-fetch-chart-groups";
// import { useInitializeMassCommunications } from "./composition/use-initialize-mass-communications";

export default defineComponent({
  props: {
    isProtected: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isProtected } = toRefs(props);

    const initializeProtectedData = () => {
      useHandleLandingPageNavigation(isProtected);
      useFetchChartGroups();
      useFetchPatientConnectClinics();
      // useInitializeMassCommunications();
    };

    useCheckJwtSessionOnVisibilityChange(isProtected);

    watch(isProtected, () => {
      if (isProtected.value) {
        initializeProtectedData();
      }
    });
  },
});
