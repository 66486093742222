
import { computed, defineComponent } from "vue";
import { router, Routes } from "@/router";
import { useStore } from "@/store";
import { ActionTypes } from "@/store/constants/action-types";
import ToolbarSideMenu from "./components/toolbar-side-menu.vue";
import ChartGroupDropDown from "./components/chart-group-drop-down.vue";
import ConfirmDependantSelectionSuccessModal from "../shared-manage-dependants/components/confirm-dependant-selection-success-modal.vue";
import ToolbarLayout from "./layout/toolbar-layout.vue";
import LogoutButtonContent from "./components/logout-button-content.vue";
import ToolbarRowButton from "./components/toolbar-row-button.vue";
import { useChartGroupsStore } from "../chart-groups/store/chart-groups-store";

export default defineComponent({
  setup() {
    const store = useStore();
    const chartGroupStore = useChartGroupsStore();

    const chartGroups = computed(() => chartGroupStore.getChartGroups);

    const goToManageDependants = () => {
      router.goTo(Routes.manageDependants);
    };

    const goToDashboard = () => {
      router.goTo(Routes.dashboard);
    };
    const logout = () => store.dispatch(ActionTypes.AUTH__LOGOUT);

    return {
      chartGroupStore,
      chartGroups,
      goToManageDependants,
      logout,
      goToDashboard,
    };
  },
  components: {
    ToolbarSideMenu,
    ChartGroupDropDown,
    ConfirmDependantSelectionSuccessModal,
    ToolbarLayout,
    LogoutButtonContent,
    ToolbarRowButton,
  },
});
