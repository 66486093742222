import { ChartGroupDto } from "./chart-group-dto.interface";
import { ChartGroup } from "./chart-group.interface";

export const createChartGroup = (dto: ChartGroupDto): ChartGroup => {
  return {
    id: dto.id,
    name: dto.name,
    primary: dto.primary,
    patientConnectClinics: dto.charts.map((chart) => ({
      id: chart.patient_connect_clinic_id,
      patientName: chart.patient_name,
      patientId: chart.patient_id,
      clinicName: chart.clinic_name,
      clinicId: chart.clinic_id,
      clinicAddress: chart.clinic_address,
      avatar: chart.avatar,
    })),
  };
};
