import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_security_icon = _resolveComponent("security-icon")!
  const _component_side_menu_item = _resolveComponent("side-menu-item")!

  return (_openBlock(), _createBlock(_component_side_menu_item, {
    isNestedItem: "",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goToMenuItem(_ctx.Routes.accountSettingsSecurity)), ["stop"]))
  }, {
    icon: _withCtx(() => [
      _createVNode(_component_security_icon)
    ]),
    title: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.securityTitle), 1)
    ]),
    _: 1
  }))
}