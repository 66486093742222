import { RouteRecordRaw } from "vue-router";
import { Routes } from "@/router/types/routes.enum";

const AuthenticationPage = () =>
  import(
    /* webpackChunkName: "the_login" */ "@/pages/authentication/authentication.vue"
  );

const LandingPage = () =>
  import(
    /* webpackChunkName: "landing_page" */ "@/pages/authentication/pages/landing-page/landing-page.vue"
  );

const SignUp = () =>
  import(
    /* webpackChunkName: "signup_form" */ "@/pages/authentication/pages/sign-up/sign-up.vue"
  );

const TwoFactorSignIn = () =>
  import(
    /* webpackChunkName: "two_factor_form" */ "@/pages/authentication/pages/two-factor-sign-in/two-factor-sign-in.vue"
  );

const ForgotPassword = () =>
  import(
    /* webpackChunkName: "forgot_password_form" */ "@/pages/authentication/pages/forgot-password/forgot-password.vue"
  );

const ResetPassword = () =>
  import(
    /* webpackChunkName: "reset_password_form" */ "@/pages/authentication/pages/reset-password/reset-password.vue"
  );

const PreVerifiedInvitation = () =>
  import(
    /* webpackChunkName: "pre_verified_invitation_form" */ "@/pages/authentication/pages/pre-verified-invitation/pre-verified-invitation.vue"
  );

export const authenticationRoute: RouteRecordRaw = {
  path: "/",
  component: AuthenticationPage,
  children: [
    {
      path: "",
      name: Routes.theAuthenticationLandingPage,
      component: LandingPage,
      meta: {
        isProtected: false,
      },
    },
    {
      path: "/login",
      name: Routes.login,
      redirect: { name: Routes.theAuthenticationLandingPage },
      meta: {
        isProtected: false,
      },
    },
    {
      path: "/sign-up",
      name: Routes.signUp,
      component: SignUp,
      meta: {
        isProtected: false,
      },
    },
    {
      path: "/two-factor-sign-in",
      name: Routes.twoFactorSignIn,
      component: TwoFactorSignIn,
      meta: {
        isProtected: false,
      },
    },
    {
      path: "/forgot-password",
      name: Routes.forgotPassword,
      component: ForgotPassword,
      meta: {
        isProtected: false,
      },
    },
    {
      path: "/reset-password",
      name: Routes.resetPassword,
      component: ResetPassword,
      meta: {
        isProtected: false,
      },
    },
    {
      path: "/pre-verified-invitation",
      name: Routes.preVerifiedInvitation,
      component: PreVerifiedInvitation,
      meta: {
        isProtected: false,
      },
    },
  ],
};
