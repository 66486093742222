import { watchEffect } from "vue";
import { useChartGroupsStore } from "../../chart-groups/store/chart-groups-store";

export function useFetchChartGroups() {
  const chartGroupsStore = useChartGroupsStore();
  watchEffect(() => {
    if (!chartGroupsStore.getIsChartGroupsFetched) {
      chartGroupsStore.fetchChartGroups();
    }
  });
}
