import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1642174c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "toast-message-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast_message = _resolveComponent("toast-message")!
  const _component_base_transition_group_fly_down_up = _resolveComponent("base-transition-group-fly-down-up")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_base_transition_group_fly_down_up, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toastMessages, (toastMessage) => {
            return (_openBlock(), _createBlock(_component_toast_message, {
              "data-testid": "toast-message",
              class: "toast-message-card",
              key: toastMessage.message,
              toastMessage: toastMessage,
              onRemoveMessage: _ctx.removeMessage
            }, null, 8, ["toastMessage", "onRemoveMessage"]))
          }), 128))
        ]),
        _: 1
      })
    ])
  ]))
}